import { Col, H1, H2, Image, PortableText, Row } from '@ahmdigital/ui';
import { isEmpty } from 'lodash/fp';
import generatePortableText from '@ahmdigital/logic/lib/content/utils/generate-portable-text';
// @ts-expect-error - Automatic, Please fix when editing this file
import getAllRewards from '@ahmdigital/offers/lib/model/offer/get-all-rewards';
import React from 'react';

import { useTileVariant } from '../../context';
import { VARIANT } from '../../constants';
import getSubtextValue from '../../utils/get-subtext-value';
import OfferHeading from '../offer-heading';
import offerTagImage from '../../../images/offer-tag.svg';
import styles from './styles.module.sass';
import useGetAllPlaceholders from '../../../logic/use-get-all-placeholders';
import type { Offer, Placeholder } from '../../../types';
import type { PortableTextType } from '../../../../../types/sanityInternal';
import type { Subtext } from '../../utils/get-content-for-offer';

type HeaderWithSubtextProps = {
  ends?: string;
  header?: PortableTextType;
  offer: Offer;
  placeholders?: Placeholder[];
  subtext?: Subtext;
};

const HeaderWithSubtext = ({
  ends,
  header,
  offer,
  placeholders: overridePlaceholders,
  subtext,
}: HeaderWithSubtextProps) => {
  const variant = useTileVariant();
  const { placeholders: fetchedPlaceholders } = useGetAllPlaceholders({ skip: !isEmpty(overridePlaceholders) });
  const placeholders = !isEmpty(overridePlaceholders) ? overridePlaceholders : fetchedPlaceholders;

  if (!placeholders) {
    return null;
  }

  const isStrip = variant === VARIANT.STRIP;
  const subtextComponent = subtext && (
    <Row>
      <Col df={12} variant={[isStrip ? styles.subtext : 'marginBottomSpacer7']}>
        <PortableText source={generatePortableText(getSubtextValue(subtext, ends))} />
      </Col>
    </Row>
  );

  const rewards = getAllRewards(offer);

  return (
    <>
      {header && (
        <Row>
          <Col df={isStrip ? 2 : 12} md={12} variant="marginBottomSpacer10">
            <Image src={offerTagImage.src} alt="Offer" />
          </Col>
          <Col df={isStrip ? 10 : 12} md={12}>
            {variant === VARIANT.HEADER ? (
              <H1>
                <OfferHeading offer={offer} placeholders={placeholders} rewards={rewards} header={header} />
              </H1>
            ) : (
              <H2 variant={isStrip ? 'marginBottomSpacer11' : 'withoutMarginBottom'} looksLike={isStrip ? 'h4' : 'h3'}>
                <OfferHeading offer={offer} placeholders={placeholders} rewards={rewards} header={header} />
              </H2>
            )}
            {isStrip && subtextComponent}
          </Col>
        </Row>
      )}
      {variant !== VARIANT.STRIP && subtextComponent}
    </>
  );
};

export default HeaderWithSubtext;
