import { get, isNil } from 'lodash/fp';
import { useQuery } from '@apollo/client';

import uniqueContentWithDraftPrecedence from '../../../../cms/utils/unique-content-with-draft-precedence';

// @ts-expect-error - Automatic, Please fix when editing this file
const useOfferQuery = (gqlQuery, options) => {
  const response = useQuery(gqlQuery, options);
  const { data } = response;
  return {
    ...response,
    data: isNil(data) ? data : { allOffer: uniqueContentWithDraftPrecedence(get('allOffer', response.data)) },
  };
};

export default useOfferQuery;
