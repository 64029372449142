import { connect } from 'react-redux';
import { isEmpty, last, reverse } from 'lodash/fp';
import React from 'react';

import { TARGET_SEGMENT } from '../../../cms/constants';
import { VARIANT, type VariantValue } from './constants';
import filterOffers from '../utils/filterOffers';
import MultiOfferDisplay from './components/multi-offer-display';
import selectReferralCode from '../../../selectors/select-referral-code';
import selectReferralUrl from '../../../selectors/select-referral-url';
import selectTargetSegment from '../../../selectors/select-target-segment';
import SingleOfferDisplay from './components/single-offer-display';
import useGetActivePromoOffer from '../logic/use-get-active-promo-offer';
import type { Offer, Placeholder } from '../types';

type OfferTileProps = {
  filterProductId?: string;
  heading?: string;
  isBoxed?: boolean;
  overrideOffers?: Offer[];
  placeholders?: Placeholder[];
  referralCode?: string;
  referralUrl?: string;
  showCodeCopy?: boolean;
  showFindCover?: boolean;
  showMemberLogin?: boolean;
  targetSegment?: string;
  variant?: VariantValue;
};

const DisplayOffers = ({
  filterProductId,
  heading,
  isBoxed,
  offers,
  placeholders,
  referralCode,
  referralUrl,
  showCodeCopy,
  showFindCover,
  showMemberLogin,
  variant,
}: Partial<OfferTileProps> & { offers: Offer[] }) => {
  if (isEmpty(offers)) {
    return null;
  }

  if (offers.length > 1 && !filterProductId) {
    const hospitalAndExtrasPrioritizedOffers = reverse(offers);

    return (
      <MultiOfferDisplay
        heading={heading}
        // @ts-expect-error - Automatic, Please fix when editing this file
        isBoxed={isBoxed}
        offers={hospitalAndExtrasPrioritizedOffers}
        placeholders={placeholders}
        // @ts-expect-error - Automatic, Please fix when editing this file
        referralCode={referralCode}
        showFindCover={showFindCover}
        // @ts-expect-error - Automatic, Please fix when editing this file
        variant={variant}
      />
    );
  }

  return (
    <SingleOfferDisplay
      // @ts-expect-error - Automatic, Please fix when editing this file
      isBoxed={isBoxed}
      // @ts-expect-error - Automatic, Please fix when editing this file
      offer={last(offers)}
      placeholders={placeholders}
      // @ts-expect-error - Automatic, Please fix when editing this file
      referralCode={referralCode}
      referralUrl={referralUrl}
      // @ts-expect-error - Automatic, Please fix when editing this file
      showCodeCopy={showCodeCopy}
      // @ts-expect-error - Automatic, Please fix when editing this file
      showFindCover={showFindCover}
      // @ts-expect-error - Automatic, Please fix when editing this file
      showMemberLogin={showMemberLogin}
      // @ts-expect-error - Automatic, Please fix when editing this file
      variant={variant}
    />
  );
};

const OfferTile = ({
  filterProductId,
  heading,
  isBoxed = true,
  overrideOffers,
  placeholders,
  referralCode,
  referralUrl,
  showCodeCopy = true,
  showFindCover = true,
  showMemberLogin,
  targetSegment = TARGET_SEGMENT.LOOKING_FOR_PRIVATE_HEALTH_INSURANCE,
  variant = VARIANT.TILE,
}: OfferTileProps) => {
  const { activeOffers } = useGetActivePromoOffer({ skip: !isEmpty(overrideOffers), targetSegment });

  if (isEmpty(overrideOffers) && isEmpty(activeOffers)) {
    return null;
  }

  if (!isEmpty(overrideOffers)) {
    const displayOffers = filterProductId ? filterOffers({ filterProductId, offers: overrideOffers }) : overrideOffers;
    return (
      // @ts-expect-error - Automatic, Please fix when editing this file
      <DisplayOffers
        {...{
          filterProductId,
          heading,
          isBoxed,
          offers: displayOffers,
          placeholders,
          referralCode,
          referralUrl,
          showCodeCopy,
          showFindCover,
          showMemberLogin,
          variant,
        }}
      />
    );
  }

  const displayOffers = filterProductId ? filterOffers({ filterProductId, offers: activeOffers }) : activeOffers;

  return (
    // @ts-expect-error - Automatic, Please fix when editing this file
    <DisplayOffers
      {...{
        filterProductId,
        heading,
        isBoxed,
        offers: displayOffers,
        placeholders,
        referralCode,
        referralUrl,
        showCodeCopy,
        showFindCover,
        showMemberLogin,
        variant,
      }}
    />
  );
};

const mapStateToProps = (state: any) => ({
  referralCode: selectReferralCode(state) as unknown as string,
  referralUrl: selectReferralUrl(state) as unknown as string,
  targetSegment: selectTargetSegment(state),
});

export default connect(mapStateToProps)(OfferTile);
export { OfferTile as OfferTileForTest };
