import { get, getOr, isNull } from 'lodash/fp';

import { ImageType } from '../../../types/image';
import imageUrlBuilder from '../image-url-builder';
import type { ImageObjectType } from '../../../types/sanity';

type GetImageAttributesProps = {
  imageObject: ImageObjectType;
  height?: number;
  width?: number;
};

const getImageAttributes = ({ imageObject, height, width }: GetImageAttributesProps): ImageType | null => {
  const image = get('image', imageObject);

  if (!get('asset', image)) {
    return null;
  }

  const caption = getOr('', 'caption', imageObject);
  return {
    alt: isNull(caption) ? '' : caption,
    // @ts-expect-error - Automatic, Please fix when editing this file
    src: imageUrlBuilder(image).height(height).width(width).url(),
  };
};

export default getImageAttributes;
