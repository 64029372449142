import { filter, isEmpty } from 'lodash/fp';
// @ts-expect-error - Automatic, Please fix when editing this file
import getEligibleRewards from '@ahmdigital/offers/lib/model/offer/get-eligible-rewards';
// @ts-expect-error - Automatic, Please fix when editing this file
import getUpsellApplicableRewards from '@ahmdigital/offers/lib/model/offer/get-upsell-applicable-rewards';
// @ts-expect-error - Automatic, Please fix when editing this file
import isOfferEligible from '@ahmdigital/offers/lib/model/offer/is-eligible';

import type { Offer } from '../../types';

// @ts-expect-error - Automatic, Please fix when editing this file
const filterOffers = ({ filterProductId, offers }): Offer[] => {
  const scenarioForRewardEligibility = { productId: filterProductId };

  const eligibleOffers = filter((offer) => isOfferEligible(offer, scenarioForRewardEligibility))(offers);

  const filteredOffers = isEmpty(eligibleOffers)
    ? filter((offer) => {
        const eligibleRewards = getEligibleRewards(offer, scenarioForRewardEligibility);

        const upsellApplicableRewards = getUpsellApplicableRewards(offer, scenarioForRewardEligibility);
        const rewards = isEmpty(eligibleRewards) ? upsellApplicableRewards : eligibleRewards;

        return !isEmpty(rewards);
      })(offers)
    : eligibleOffers;

  return filteredOffers as Offer[];
};

export default filterOffers;
