import { Col, H1, Image, Row } from '@ahmdigital/ui';
import { map } from 'lodash/fp';
import React, { ComponentProps, Fragment } from 'react';
import styled from 'styled-components';

import OfferWell from '../offer-well';
import orDivider from './or-divider.svg';
import TileWrapper from '../tile-wrapper';
import uncapFpIterator from '../../../../../../utils/uncap-fp-iterator';
import type { Offer } from '../../../types';

type MultiOfferDisplayProps = Pick<ComponentProps<typeof TileWrapper>, 'isBoxed' | 'variant'> &
  Pick<ComponentProps<typeof OfferWell>, 'placeholders' | 'referralCode' | 'referralUrl' | 'showFindCover'> & {
    offers: Offer[];
    heading?: string;
  };

const OrDividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const StyledRow = styled(Row)`
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.media.md}) {
    margin: 0 ${({ offers }) => (offers.length === 3 ? `-5rem` : `-3rem`)};
  }

  @media (min-width: ${({ theme }) => theme.media.lg}) {
    margin: 0 ${({ offers }) => (offers.length === 3 ? `-8rem` : `-3rem`)};
  }
`;

const MultiOfferDisplay = ({
  isBoxed,
  offers,
  placeholders,
  referralCode,
  referralUrl,
  showFindCover,
  variant,
  heading,
}: MultiOfferDisplayProps) => {
  const offersToDisplay = offers.slice(0, 3);
  const offerDf = offersToDisplay.length === 3 ? 3 : 4;
  const orCircleDf = offersToDisplay.length === 3 ? 0 : 2;

  return (
    <TileWrapper variant={variant} isBoxed={isBoxed}>
      {heading && (
        <HeadingWrapper>
          <H1 looksLike="h2">{heading}</H1>
        </HeadingWrapper>
      )}
      <StyledRow offers={offersToDisplay}>
        {/* @ts-expect-error - Automatic, Please fix when editing this file */}
        {uncapFpIterator(map)((offer, index) => (
          <Fragment key={index}>
            <Col df={offerDf} md={12}>
              {/* @ts-expect-error - Automatic, Please fix when editing this file */}
              <OfferWell
                offer={offer}
                placeholders={placeholders}
                referralCode={referralCode}
                referralUrl={referralUrl}
                showFindCover={showFindCover}
              />
            </Col>
            {offersToDisplay.length > 1 && index < offersToDisplay.length - 1 && (
              <Col df={orCircleDf} md={12}>
                <OrDividerWrapper>
                  <Image src={orDivider.src} alt="or" />
                </OrDividerWrapper>
              </Col>
            )}
          </Fragment>
        ))(offersToDisplay)}
      </StyledRow>
    </TileWrapper>
  );
};

MultiOfferDisplay.defaultProps = {
  heading: undefined,
};

export default MultiOfferDisplay;
