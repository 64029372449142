import { get } from 'lodash/fp';

import getImageAttributes from '../../../../cms/utils/get-image-attributes';

// @ts-expect-error - Automatic, Please fix when editing this file
const getOfferImage = (offer) => {
  const imageObject = get('offerTypes[0].imageObject', offer);
  return getImageAttributes({ imageObject, width: 400 });
};

export default getOfferImage;
